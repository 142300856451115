import axios from 'axios';

console.log(process.env.REACT_APP_API_URL);

var url = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({ baseURL: url });

console.log("URL: ", url);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong!')
);

export default axiosInstance;
