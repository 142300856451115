import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';


const RequestPage = Loadable(lazy(() => import('./RequestPage')));


const requestRoutes = [
    { path: '/auth/ig/management/request', element: <RequestPage />, auth: [authRoles.sa] },
    
    
];

export default requestRoutes;
