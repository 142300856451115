import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const MainTickets = Loadable(lazy(() => import('./MainTickets')));
const AdminTicketsPage = Loadable(lazy(() => import('./AdminTicketsPage')));


const taskListRoutes = [
    { path: '/auth/Tickets/Main', element: <MainTickets />, auth: [authRoles.admin] },
    { path: '/auth/Tickets/Admin', element: <AdminTicketsPage />, auth: [authRoles.admin] },
];

export default taskListRoutes;
