import { Avatar, Box, Divider, Icon, IconButton, styled, TextField, useTheme } from '@mui/material';
import { ChatAvatar, MatxLoading } from 'app/components';
import { convertHexToRGB } from 'app/utils/utils';
import { useCallback, useEffect, useState, useRef } from 'react';
import ScrollBar from 'react-perfect-scrollbar';

import { H5, Span } from './Typography';
import axios from 'axios';
import moment from 'moment';
import useSignalR from '../hooks/useSignalR';

const ChatContainer = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
}));

const StyledScrollBar = styled(ScrollBar)(() => ({
    flexGrow: 1,
}));

//const StyledScrollBar = styled('div')(() => ({
//    flexGrow: 1,
//}));


const ProfileBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 20px',
    color: theme.palette.primary.main,
    background: '#fafafa',
}));

const ChatStatus = styled('div')(({ theme }) => ({
    marginLeft: '12px',
    color: theme.palette.primary.light,
    '& h5': {
        marginTop: 0,
        fontSize: '14px',
        marginBottom: '3px',
    },
    '& span': { fontWeight: '500' },
}));

const ChatMessage = styled('div')(({ theme }) => ({
    padding: '8px',
    maxWidth: 240,
    fontSize: '14px',
    borderRadius: '4px',
    marginBottom: '8px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    color: theme.palette.primary.main,
    background: '#fafafa',
}));

const MessageTime = styled('span')(({ theme }) => ({
    fontSize: '13px',
    fontWeight: '500',
    color: theme.palette.primary.main,
}));


const Chatbox = ({ togglePopup, user = null }) => {
    const [isAlive, setIsAlive] = useState(true);
    const [message, setMessage] = useState('');
    const [messageList, setMessageList] = useState(null);
    const currentUserId = user?.id;
    const chatBottomRef = document.querySelector('#chat-scroll');
    const { connectionHub } = useSignalR();
    const { palette } = useTheme();
    const primary = palette.primary.main;
    const textPrimary = palette.text.primary;
    const div = useRef(null);

    useEffect(() => {
        if (connectionHub) {
            connectionHub.on('SendMessageToUser', (userId, message) => {
                if (message?.destinId?.toString() === userId) {
                    setMessageList([...messageList, message]);
                }
            });
        }

    }, [connectionHub, messageList, currentUserId]);

    const sendMessageOnEnter = async (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            sendMessage();
        }
    };

    const sendMessageOnClick = async (event) => {
        sendMessage();
    };


    const sendMessage = async () => {

        let tempMessage = message.trim();
        let messageObject = { text: tempMessage, destinId: user?.id, };

        var response = await SendMessage(messageObject);
        setMessageList([...messageList, response]);

        if (connectionHub !== null) {
            if (connectionHub?.state === "Connected") {
                connectionHub.invoke('SendMessageToUser', user?.id?.toString(), response).then(() => {
                    setMessage('');
                })
                    .catch(error => console.error('Error invoking method:', error));
            } else {
                connectionHub.start().then(() => {
                    connectionHub?.invoke("JoinUser", user?.id?.toString());
                })
            }
        } else {
            connectionHub?.start().then(() => {
                connectionHub?.invoke("JoinUser", user?.id?.toString());
            })
        }
    }


    const scrollToBottom = useCallback(() => {
        if (chatBottomRef) {
            chatBottomRef.scrollTo({ top: chatBottomRef.scrollHeight, behavior: 'smooth' });
        }
        //div.current.scrollIntoView({ behavior: "smooth", block: "end" })
    }, [chatBottomRef]);


    const GetMessages = async () => {
        try {
            const response = await axios.get('/Message/GetMessages', { params: { AccountId: user?.id || 0 } });
            var resp = response.data;
            return resp;
        } catch (err) {
            return [];
        }
    }

    const SendMessage = async (createMessage) => {
        try {
            const response = await axios.post('/Message/SendMessage', createMessage);
            var resp = response.data;
            return resp;
        } catch (err) {
            return [];
        }
    }

    useEffect(() => {
        if (isAlive) {
            (async () => {
                var response = await GetMessages(user?.id);
                setMessageList(response);
            })();
        }
    }, [isAlive]);

    useEffect(() => {
        scrollToBottom();
        return () => setIsAlive(false);
    }, [messageList, scrollToBottom]);

    return (
        <ChatContainer >
            <ProfileBox>
                <Box display="flex" alignItems="center">
                    <ChatAvatar
                        src={user?.avatar !== null ? user?.avatar : "/assets/images/face-2.jpg"}
                        status={(user?.refreshTokens || []).some(x => x.isActive === true) ? "online" : ""}
                    />
                    <ChatStatus>
                        <H5>{user?.firstName + " " + user?.lastName}</H5>
                        <Span>{(user?.refreshTokens || []).some(x => x.isActive === true) ? "Ativo" : "Ausente"}</Span>
                    </ChatStatus>
                </Box>
                <IconButton onClick={togglePopup}>
                    <Icon fontSize="small">clear</Icon>
                </IconButton>
            </ProfileBox>
            <StyledScrollBar ref={div}>
                {messageList === null ?
                    <MatxLoading />
                    :
                    (messageList || []).sort((a, b) => b.time - a.time).map((item, ind) => (

                        <Box
                            key={ind}
                            p="20px"
                            display="flex"
                            sx={{ justifyContent: currentUserId === item.destinId && 'flex-end' }}>
                            {currentUserId !== item.destinId && <Avatar src={item.avatar} />}
                            <Box ml="12px">
                                {currentUserId !== item.destinId && (
                                    <H5 sx={{ mb: '4px', fontSize: '14px', color: primary, }}>
                                        {item.name}
                                    </H5>
                                )}
                                <ChatMessage>{item.text}</ChatMessage>
                                <MessageTime>{moment(item.time).fromNow()}</MessageTime>
                            </Box>
                        </Box>

                    ))
                }
            </StyledScrollBar>
            <div>
                <Divider sx={{ background: `rgba(${convertHexToRGB(textPrimary)}, 0.15)` }} />
                <TextField
                    placeholder="Escrever aqui ..."
                    multiline
                    rowsmax={4}
                    fullWidth
                    sx={{ '& textarea': { color: primary } }}
                    InputProps={{
                        endAdornment: (
                            <Box display="flex">
                                <IconButton size="small" onClick={sendMessageOnClick}>
                                    <Icon>send</Icon>
                                </IconButton>
                            </Box>
                        ),
                        classes: { root: 'pl-5 pr-3 py-3 text-body' },
                    }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyUp={sendMessageOnEnter}
                />
            </div>
        </ChatContainer>
    );
};

export default Chatbox;