
export function DocLineInit(nLine = 0, headerId = null) {
    return {

        id: null,
        headerId: headerId,
        movStock: false,
        nLine: nLine,
        prodRef: "",
        prodDesc: "",
        qtd: 0,
        codPrice: 0,
        price: 0,
        taxIva: 0,
        desc1: 0,
        desc2: 0,
        desc3: 0,
        desc4: 0,
        totalIva: 0,
        totalDesc: 0,
        totalIliq: 0,
        totalLine: 0,
        observation: ""


    };
}

export function EntityInit() {
    return {
        id: null,
        entityStatusId: 0,
        entityStatus: null,
        name: "",
        nif: "",
        notes: "",
        sync: false,
        entityAdress: [],
        entityCompany: [],
        entityContact: []
    };
}

export function EntityAdressInit(fields = []) {
    return {
        id: 0,
        adress: "",
        location: "",
        postalCode: "",
        city: "",
        country: "",
        latitude: 0,
        longitude: 0,
        entityField: fields
    };
}

export function EntityCompanyInit() {
    return {
        id: 0,
        companyId: 0,
        name: "",
        count: "",
        number: "",
    };
}

export function EntityContactInit(fields = []) {
    return {
        id: 0,
        name: "",
        phone: "",
        email: "",
        function: "",
        observations: "",
        entityField: fields
    };
}

export function StorageInit(company = null) {
    return {
        id: null,
        companyId: company?.id || 0,
        identification: "",
        name: "",
        description: "",
        observation: "",
    };
}

export function LocationInit(company = null, currentStorage = null) {
    return {
        id: null,
        companyId: company?.id || null,
        storage: currentStorage || null,
        storageId: currentStorage?.id || null,
        identification: "",
        floor: "",
        hall: "",
        column: "",
        level: "",
        subLevel: ""
    };
}

export function EquipmentListInit(company = null) {
    return {
        id: null,
        companyId: company?.id || 0,
        list: ""
    };
}

export function EquipmentTypeInit(company = null) {
    return {
        id: null,
        companyId: company?.id || 0,
        type: ""
    };
}
/*
export function EquipmentInit(company = null) {
    return {
        id: null,
        companyId: company?.id || 0,
        equipmentListId: null,
        equipmentList: null,
        equipmentTypeId: null,
        equipmentType: null,
        active: true,
        code: "",
        designation: "",
        iOEF: "",
        pMP: "",
        iMM: "",
        entityId: null,
        entity: null,
        storageId: null,
        storage: null,
        locationId: null,
        location: null,
        observations: ""
    };
}*/

export function EquipmentListFilterInit(company = 0) {
    return {
        companyId: company?.id || 0
    };
}

export function EquipmentTypeFilterInit(company = 0) {
    return {
        companyId: company?.id || 0
    };
}

export function FilterOptionsInit(companyId = null, paginate = true) {
    return {
        companyId: companyId,
        search: "",
        paginated: paginate
    };
}

/*
export function InventoryFilterInit(company, all = false) {
    return {
        companyId: company?.id || 0,
        all: all
    };
}*/

export function AccountFilterOptionsInit(company = null) {
    return {
        companyId: company?.id || null,
        search: "",

    };
}

export function EntityFilterOptionsInit(company = null) {

    console.log(company);

    return {
        companyId: company?.id || null,
        search: "",

    };
}

export function ArticleFilterOptionsInit(company, articleType = null, active = true) {
    return {
        companyId: company?.id || 0,
        search: "",
        active: active,
        articleType: articleType
    };
}

export function DepartmentFilterOptionsInit(company) {
    return {
        companyId: company?.id ?? null,
        search: ""
    };
}

export function RhFilterInit(company) {
    return {
        companyId: company?.id ?? null,
        search: ""
    };
}

export function RhCurrentFilterInit(company, years = 1) {
    return {
        companyId: company?.id ?? null,
        years: years
    };
}

export function ArticlTypeFilterInit(company = null) {
    return {
        companyId: company?.id || 0,
    };
}

export function UnitsFilter(company = null) {
    return {
        companyId: company?.id || 0,
    };
}

export function EventInit() {
    return {
        id: null,
        companyId: 1,
        date: new Date(),
        name: "",
        notes: "",
        isActive: true,
        created: new Date(),
        updated: null,
        eventInscriptions: [],
    };
}

export function EventCompanyInit() {
    return {
        id: 0,
        companyId: 0,
        name: "",
        count: "",
        number: "",
    };
}

export function EventInscriptionsInit() {
    return {
        id: 0,
        eventId: 0,
        entityContactId: 0,
        isActive: true,
        isAttended: false,
        created: new Date(),
        updated: null,
        event: null,
        entityContact: null
    };
}

export function EventsFilterOptionsInit(company = null) {
    return {
        companyId: company?.id || null,
        search: "",

    };
}


export function LicenseGenerateInit(company = null) {
    return {
        id: null,
        companyId: company?.id ?? null,
        expire: null,
        key: "",
        path: "",
        check: null
    };
}


export function PaginationInit(initPage = -1) {
    return {
        HasNext: false,
        HasPrevious: false,
        Page: initPage,
        RowsPerPage: 10,
        TotalCount: 0,
        TotalPages: 0
    };
}


export function UpdatePasswordInit() {
    return {
        oldPassword: "",
        password: "",
        confirmPassword: ""
    };
}

export function RecoverPasswordInit() {
    return {
        token: "",
        password: "",
        confirmPassword: ""
    };
}



export function FilterStockInit(company) {
    return {
        companyId: company?.id || null,
        location: "",
        reference: ""
    };
}

export function MovimentInit(company) {

    console.log(company)

    return {
        companyId: company?.id || null,
        originLocation: "",
        destinLocation: "",
        reference: "",
        quantity: 0,
        observation: ""
    };
}


export function BudgetInit(company = null) {
    return {
        id: null,
        companyId: company?.id || null,
        numDoc: 0,
        dailyId: null,
        daily: null,
        year: new Date().getFullYear(),
        entityId: null,
        entityName: "",
        entityNif: "",
        entityContact: "",
        observations: "",
        totalIlliquid: 0,
        totalDiscount: 0,
        totalTax: 0,
        total: 0,
        documentFields: [],
        lines: [],//BudgetLine


        //ADICIONAL FIELDS
        feedstocks: [],//BudgetLine
        pigments: [],//BudgetLine
        molds: [],//BudgetLine
        expenses: [],//BudgetLine
        hresources: [], //BudgetLine
        machines: [], //BudgetLine
        packaging: [],
        assocDocs: [],
        definitions: BudgetInitDefinitions(),

    };
}

export function DocumentInit(company = null) {
    return {
        id: null,
        companyId: company?.id || null,
        numDoc: 0,
        dailyId: null,
        daily: null,
        year: new Date().getFullYear(),
        entityId: null,
        entityName: "",
        entityNif: "",
        entityContact: "",
        observations: "",
        totalIlliquid: 0,
        totalDiscount: 0,
        totalTax: 0,
        total: 0,
        documentFields: [],
        lines: [],
    };
}

export function ProdSettingsInit() {
    return {
        budgetDoc: [],
        machines: [],
        molds: [],
        hresources: [],
        definitions: null,
    };
}

export function BudgetInitDefinitions() {
    return {weight: null, cycles: null, workTime: null, productionHour: 0};
}


export function BudgetLineInit(
    article,
    pieces = 1,
    edit = true
) {
    return {
        id: null,
        nr: 0,
        subLineOrigin: null,
        subLine: [],
        docOrigin: null,
        lineOrigin: null,
        documentId: null,
        movStock: (article?.movStock || true),
        quantity: (article?.quantity || 0), //(pieces * (article?.quantity || 0)),
        articleId: article?.id || null,
        reference: article?.reference || null,
        description: article?.description || null,
        tax: article?.selectedArticlePrice?.tax || 0,
        price: article?.selectedArticlePrice?.price || 0,
        unit: (article?.selectedArticlePrice?.unit || "€"),
        totalIlliquid: +((article?.quantity || 0) * (article?.selectedArticlePrice?.price || 0)).toFixed(5),
        totalDiscount: 0,
        totalTax: 0,
        totalLine: +((article?.quantity || 0) * (article?.selectedArticlePrice?.price || 0)).toFixed(5),//((pieces * (article?.quantity || 0)) * (article?.selectedArticlePrice?.price || 0)).toFixed(3),
        edit: edit
    };
}






export function BudgetInvestLineInit(
    pieces = 0,
    priceUnit = 0,
    reference = "",
    description = "",
    unit = "€"
) {
    return {
        id: null,
        nr: 0,
        docOrigin: null,
        lineOrigin: null,
        documentId: null,
        movStock: false,
        quantity: pieces,
        articleId: null,
        reference: reference,
        description: description,
        tax: 0,
        price: priceUnit,
        unit: unit,
        totalIlliquid: (pieces * priceUnit).toFixed(3),
        totalDiscount: 0,
        totalTax: 0,
        totalLine: (pieces * priceUnit).toFixed(3),
        edit: false

    };


}


export function BudgetExpenseLineInit(expenseLine, currentSubTotal) {
    const {quantity, unit, reference, description, price, expenseType} = expenseLine || {};
    //id = 1 -> fixo
    //id = 2 -> variavel

    if (!expenseType?.id) {
        return null; // Handle the case where id is not provided
    }

    const numericQuantity = parseFloat(+quantity) || 0;
    const numericPrice = parseFloat(+price) || 0;
    currentSubTotal = parseFloat(+currentSubTotal) || 0;

    console.log(currentSubTotal);

    var totalLine = 0;

    switch (expenseType?.id) {
        case 1:
            totalLine = +(numericQuantity * numericPrice).toFixed(3);
            break;
        case 2:
            totalLine = +((numericQuantity / 100) * currentSubTotal).toFixed(3);
            break;
        default:
            totalLine = 0
            break;
    }


    return {
        id: expenseLine?.id || null,
        docOrigin: null,
        nr: expenseLine?.nr || null,
        lineOrigin: null,
        documentId: null,
        movStock: false,
        quantity: numericQuantity,
        articleId: null,
        reference: reference || "",
        description: description || "Custos",
        tax: 0,
        price: (totalLine / numericQuantity).toFixed(3),
        unit: unit || "",
        totalIlliquid: 0,
        totalDiscount: 0,
        totalTax: 0,
        totalLine: (+totalLine || 0)?.toFixed(3),
        edit: true
    };
}


export function BudgetDocumentLineInit(
    currentDoc = null,
    docOrigin = null,
    lineOrigin = null,
    quantity = 1
) {
    if (currentDoc === null || (docOrigin === null && lineOrigin === null) || ((docOrigin !== null && lineOrigin !== null))) {
        return null;
    }

    var lines = [];

    var originalline = {
        id: (docOrigin?.lines || []).find(x => x.docOrigin === (docOrigin?.numDoc + "_" + docOrigin?.daily + "_" + docOrigin?.year))?.id || null,
        subLineOrigin: null,
        docOrigin: docOrigin !== null ? (docOrigin?.numDoc + "_" + docOrigin?.daily + "_" + docOrigin?.year) : null,
        lineOrigin: lineOrigin !== null ? (lineOrigin?.numDoc + "_" + lineOrigin?.daily + "_" + lineOrigin?.year + "_" + lineOrigin?.nr) : null,
        documentId: currentDoc?.id,
        movStock: false,
        quantity: +quantity,
        articleId: null,
        reference: ".",
        description: (docOrigin !== null ?
            (((docOrigin?.documentFields || []).find(x => x?.name === "ARTICLENAME")?.value ?? " -- ") + " [" + docOrigin?.numDoc + "_" + docOrigin?.daily + "_" + docOrigin?.year + "]") :
            ("Carregado da linha do documento : " + lineOrigin?.numDoc + "_" + lineOrigin?.daily + "_" + lineOrigin?.year + "_" + lineOrigin?.nr)),
        tax: 0,
        price: +(docOrigin?.total).toFixed(3),
        unit: "€",
        totalIlliquid: +(docOrigin?.totalIlliquid ?? 0),
        totalDiscount: 0,
        totalTax: 0,
        totalLine: +(quantity * (docOrigin?.total ?? 0)?.toFixed(3)),
        edit: false,
        editQTD: true
    };

    console.log(originalline);


    lines.push(originalline);
    var resp = BudgetDocumentSubLineInit(docOrigin, originalline);
    resp.forEach(subLine => {
        lines.push(subLine);
    });

    return lines;
}


export function BudgetDocumentSubLineInit(
    docOrigin = null,
    originalline
) {
    if (docOrigin === null) {
        return null;
    }
    var subLines = [];
    (docOrigin.lines || []).forEach(l => {
        var lineadd = {
            id: null,
            subLineOrigin: ( l?.nr + "#" + originalline?.docOrigin) ,
            docOrigin: originalline?.docOrigin || null,
            lineOrigin: l.lineOrigin || null,
            documentId: originalline.documentId,
            movStock: l.movStock,
            quantity: +(/*originalline.quantity **/ l.quantity),
            articleId: l.articleId,
            reference: l.reference,
            description: l.description,
            tax: +l.tax,
            price: (+l.price).toFixed(3),
            unit: l.unit,
            totalIlliquid: +(/*originalline.quantity * */l.totalIlliquid),
            totalDiscount: +(/*originalline.quantity * */l.totalDiscount),
            totalTax: +(/*originalline.quantity **/ l.totalTax),
            totalLine: +((/*originalline.quantity * */l.totalLine)?.toFixed(3)),
            edit: false,
            editQTD: true
        }
        subLines.push(lineadd);
    });
    return subLines;
}


/*
*export function BudgetDocumentLineInit(
    docOrigin = null,
    lineOrigin = null,
    quantity = 1
) {
    if ((docOrigin === null && lineOrigin === null) || ((docOrigin !== null && lineOrigin !== null))) {
        return null;
    }
    console.log(docOrigin);


    return {
        id: null,
        //subLineOrigin : docOrigin?.lines || [],
        docOrigin: docOrigin !== null ? (docOrigin?.numDoc + "_" + docOrigin?.daily + "_" + docOrigin?.year) : null,
        lineOrigin: lineOrigin !== null ? (lineOrigin?.numDoc + "_" + lineOrigin?.daily + "_" + lineOrigin?.year + "_" + lineOrigin?.nr) : null,
        documentId: null,
        movStock: false,
        quantity: quantity,
        articleId: null,
        reference: ".",
        description: (docOrigin !== null ?
            (((docOrigin?.documentFields || []).find(x => x?.name === "ARTICLENAME")?.value ?? " -- ") + " [" + docOrigin?.numDoc + "_" + docOrigin?.daily + "_" + docOrigin?.year + "]") :
            ("Carregado da linha do documento : " + lineOrigin?.numDoc + "_" + lineOrigin?.daily + "_" + lineOrigin?.year + "_" + lineOrigin?.nr)),
        tax: 0,
        price: (docOrigin?.total).toFixed(3),
        unit: "€",
        totalIlliquid: docOrigin?.totalIlliquid ?? 0,
        totalDiscount: 0,
        totalTax: 0,
        totalLine: quantity * (docOrigin?.total ?? 0)?.toFixed(3),
        edit: false,
        editQTD: true
    };
}
* */


export function BookingCalendarInit({
                                        id = null,
                                        companyId = null,
                                        accountId = null,
                                        day = null,
                                        morning = true,
                                        afternoon = true
                                    }) {

    if (companyId === null)
        return null;
    if (accountId === null)
        return null;

    return {
        id: null,
        companyId: companyId,
        accountId: accountId,
        day: day,
        morning: morning,
        afternoon: afternoon,
    };
}
/*
export function GanttProjectInit(data) {
    var currentDate = new Date();

    return {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDay()),
        end: new Date(currentDate.getFullYear(), 12, 31),
        name: data?.name,
        id: data?.id,
        progress: 100,
        type: "project",
        hideChildren: true,
        displayOrder: 1,
        data: data

    };
}*/
/*
export function GanttTaskInit(data) {

    return {
        start: new Date(data?.day),
        end: new Date(data?.day),
        name: data?.account?.firstName,
        project: data?.account?.departmentId,
        id: data?.id,
        dependencies: [data?.account?.departmentId],
        progress: data?.accepted ? 100 : 0,
        type: "task",
        hideChildren: false,
        //displayOrder: 2,
        //data: data

    };
}
*/

export function ReactSchedulerUser(user, departments) {
    var departmentName = (departments || []).find(x => x.id === user?.departmentId)?.name || "Sem Departamento";

    return {
        id: user?.id,
        label: {
            icon: user?.avatar,
            title: (user?.firstName + " " + user?.lastName),
            subtitle: departmentName
        },
        data: []

    };
}

export function ReactSchedulerData(data, selected = false) {
    const startDateTime = new Date(data.day);
    if (data.morning) {
        startDateTime.setHours(8, 0, 0, 0);
    } else {
        startDateTime.setHours(14, 0, 0, 0);
    }
    const endDateTime = new Date(data.day);
    if (data.afternoon) {
        endDateTime.setHours(19, 0, 0, 0);
    } else {
        endDateTime.setHours(14, 0, 0, 0);
    }

    return {
        id: data?.id,
        startDate: startDateTime,
        endDate: endDateTime,
        occupancy: 3600,
        title: "Férias",
        subtitle: "",
        description: "",
        bgColor: ReactSchedulerDataColorSchema(data, selected),
        selected: selected,
        data: data

    };
}


export function ReactSchedulerDataColorSchema(data, selected = false) {

    var stateColor = "rgba(254,165,177,0)"
    if (selected === false) {

        if (data?.canceled === true) {
            stateColor = "rgb(252,0,0)"
        } else if (data?.accepted === false) {
            stateColor = "rgb(248,203,60)"
        } else if (data?.accepted === true && data?.used === false) {
            stateColor = "rgb(87,217,53)"
        } else if (data?.accepted === true && data?.used === true) {
            stateColor = "rgb(29,131,0)"
        }
        return stateColor;
    } else {
        return "rgb(227,98,26)"
    }

}


export function RhBookingState(data) {
    var state = null;

    if (data?.canceled === true) {
        state = "Cancelado";
    } else if (data?.accepted === false) {
        state = "Pendente";
    } else if (data?.accepted === true && data?.used === false) {
        state = "Aceite";
    } else if (data?.accepted === true && data?.used === true) {
        state = "Gozado";
    }

    return state;
}






