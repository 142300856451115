//import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { CompanyProvider } from './contexts/CompanyContext';
import { Store } from './redux/Store';
import routes from './routes';
import { SignalRProvider } from './contexts/SignalRContext';
import "@bitnoi.se/react-scheduler/dist/style.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";

export const version = "20240214";

const App = () => {
    const content = useRoutes(routes);
    return (
            <Provider store={Store}>
                <SettingsProvider>
                    <MatxTheme>
                        <AuthProvider>
                            <CompanyProvider>
                                <SignalRProvider>
                                    {content}
                                </SignalRProvider>
                            </CompanyProvider>
                        </AuthProvider>
                    </MatxTheme>
                </SettingsProvider>
            </Provider>
     
    );
};

export default App;
