import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PermitionsPage = Loadable(lazy(() => import('./PermitionsPage')));
const RolesPage = Loadable(lazy(() => import('./RolesPage')));
const CompanyPage = Loadable(lazy(() => import('./CompanyPage')));
const AdicionalFieldsPage = Loadable(lazy(() => import('./AdicionalFieldsPage')));
const TablesPage = Loadable(lazy(() => import('./TablesPage')));
const ReportPage = Loadable(lazy(() => import('./ReportPage')));
const DepartmentPage = Loadable(lazy(() => import('./DepartmentPage')));
const WebHooksPage = Loadable(lazy(() => import('./WebHooksPage')));


const configurationsRouter = [
    { path: '/auth/config/pages/list', element: <PermitionsPage />, auth: [] },
    { path: '/auth/config/permitions/list', element: <RolesPage />, auth: [] },
    { path: '/auth/config/company/list', element: <CompanyPage />, auth: [] },
    { path: '/auth/config/adicionalFields', element: <AdicionalFieldsPage />, auth: [] },
    { path: '/auth/config/tables', element: <TablesPage />, auth: [] },
    { path: '/auth/config/report', element: <ReportPage />, auth: [] },
    { path: '/auth/config/webHooks', element: <WebHooksPage />, auth: [] },
    { path: '/auth/config/departments', element: <DepartmentPage />, auth: [] },
];

export default configurationsRouter;
