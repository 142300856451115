import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Profile = Loadable(lazy(() => import('./Profile')));

const profileRoutes = [
    { path: '/auth/profile', element: <Profile />, auth: [authRoles.admin] },
    
];

export default profileRoutes;
