import React, { createContext, useState } from 'react'
import { merge } from 'lodash'
import { MatxLayoutSettings } from 'app/components/MatxLayout/settings'
import { useEffect } from 'react'
import { Decrypt } from '../utils/Utilities'

const SettingsContext = createContext({
    settings: MatxLayoutSettings,
    updateSettings: () => { },
})

export const SettingsProvider = ({ settings, children }) => {
    const [currentSettings, setCurrentSettings] = useState(
        settings || MatxLayoutSettings
    )

    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update);
        setCurrentSettings(marged)
    }




    useEffect(() => {
        (async () => {
            try {
                const selectedCompany = window.localStorage.getItem('selectedCompany')
                var decryptedSelectedCompany = Decrypt(selectedCompany);
                if (decryptedSelectedCompany !== null) {

                    if ((decryptedSelectedCompany?.themeSettings || null) != null) {
                        var jsonData = JSON.parse(decryptedSelectedCompany?.themeSettings);
                        handleUpdateSettings(decryptedSelectedCompany?.themeSettings !== undefined ? jsonData : MatxLayoutSettings);
                    } else {
                        handleUpdateSettings(MatxLayoutSettings);
                    }
                }

            } catch (err) {
                console.error(err);
            }
        })();

    }, [])

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                updateSettings: handleUpdateSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext
