import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const DocsPage = Loadable(lazy(() => import('./DocsPage')));
const NewDoc = Loadable(lazy(() => import('./NewDoc')));


const docRoutes = [
    { path: '/auth/doc/docs', element: <DocsPage />, auth: [authRoles.sa] },
    { path: '/auth/doc/new', element: <NewDoc />, auth: [authRoles.sa] },
    
    
];

export default docRoutes;
