import { SnackMessage } from "app/utils/Utilities";
import { useState } from 'react';
import useSignalR from 'app/hooks/useSignalR';
import { useEffect } from "react";
import useAuth from "app/hooks/useAuth";



const SignalR = () => {
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const { connectionHub } = useSignalR();
    const { user } = useAuth();


    useEffect(() => {

        connectionHub?.on("SendMessageToAllClients", message => {
            setSnackbarMessage(SnackMessage(message, () => setSnackbarMessage(null), "success", 3000));
        });

        connectionHub?.on("SendMessageToGroup", (companyId, message) => {
            setSnackbarMessage(SnackMessage(message, () => setSnackbarMessage(null), "success", 3000));
        });

        connectionHub?.on('SendMessageToUser', (userId, message) => {
            if (user?.id?.toString() === userId)
                setSnackbarMessage(SnackMessage(("Recebeu uma mensagem de " + message?.name), () => setSnackbarMessage(null), "success", 3000));
        });

    }, [connectionHub])


    return (<>{snackbarMessage}</>);
};

export default SignalR;
