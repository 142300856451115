import { CircularProgress } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useState } from 'react';
import { LoadLocalStorage } from '../utils/Utilities';

const StyledLoading = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        width: 'auto',
        height: '25px',
    },
    '& .circleProgress': {
        position: 'absolute',
        left: -7,
        right: 0,
        top: 'calc(50% - 25px)',
    },
}));

const Loading = () => {
    const [selectedCompany] = useState(LoadLocalStorage('selectedCompany') || null);
    return (
        <StyledLoading>
            <Box position="relative">
                <img src={selectedCompany?.icon ? selectedCompany?.icon : process.env.REACT_APP_LOADING_IMAGE !== "" ? process.env.REACT_APP_LOADING_IMAGE : "/assets/images/logo-circle.svg"} alt="" />
                <CircularProgress className="circleProgress" />
            </Box>
        </StyledLoading>
    );
};

export default Loading;
