import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Main = Loadable(lazy(() => import('./Main')));
const EntityListEquipments = Loadable(lazy(() => import('./EntityListEquipments')));
const Settings = Loadable(lazy(() => import('./Settings')));


const maintenanceRoutes = [
    { path: '/auth/maintenance', element: <Main />, auth: [authRoles.sa] },
    { path: '/auth/maintenance/entities', element: <EntityListEquipments />, auth: [authRoles.sa] },
    { path: '/auth/maintenance/settings', element: <Settings />, auth: [authRoles.sa] },

    
];

export default maintenanceRoutes;
