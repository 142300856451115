import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const SettingsPage = Loadable(lazy(() => import('./SettingsPage')));

const settingsRoutes = [
    { path: '/auth/settings', element: <SettingsPage />, auth: [] },
];

export default settingsRoutes;
