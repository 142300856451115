import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';


const ListPage = Loadable(lazy(() => import('./ListPage')));
//const NewArticle = Loadable(lazy(() => import('./NewArticle')));
const ChargeArticle = Loadable(lazy(() => import('./ChargeArticle')));

const pimRoutes = [
    { path: '/auth/pim', element: <ListPage />, auth: [authRoles.sa] },
    //{ path: '/auth/pim/list', element: <ListPage />, auth: [authRoles.sa] },
    //{ path: '/auth/pim/add', element: <NewArticle />, auth: [authRoles.sa] },
    { path: '/auth/pim/charge/file', element: <ChargeArticle />, auth: [authRoles.sa] },
    
];

export default pimRoutes;
