import { IconButton, Card, Icon, Tooltip } from '@mui/material';
import { Box, styled } from '@mui/system';

const CardRoot = styled(Card)(() => ({
    height: '100%',
    padding: '20px 24px',
}));

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && '16px',
}));

const SimpleCard = ({ children, title = null, subtitle, handleClick, icon = null, colorIcon = "success", backgroundColor = "", disabled = false, tooltipButton = null }) => {
    return (
        <CardRoot elevation={6} style={{ backgroundColor: backgroundColor }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {title !== null && <CardTitle subtitle={subtitle}>{title}</CardTitle>}
                {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
                {handleClick &&
                    <>
                        {tooltipButton !== null ?
                        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{tooltipButton}</div>}>
                                <div>
                                    <IconButton disabled={disabled} onClick={handleClick}>
                                        <Icon color={disabled ? "disabled" : colorIcon}>{icon === null ? "delete" : icon}</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                            :
                            <IconButton disabled={disabled} onClick={handleClick}>
                                <Icon color={disabled ? "disabled" : colorIcon}>{icon === null ? "delete" : icon}</Icon>
                            </IconButton>
                        }

                    </>
                }
            </div>
            {children}
        </CardRoot>
    );
};

export default SimpleCard;
