import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';


const ControlPage = Loadable(lazy(() => import('./ControlPage')));


const controlRoutes = [
    { path: '/auth/ig/management/control', element: <ControlPage />, auth: [authRoles.sa] },
    
    
];

export default controlRoutes;
