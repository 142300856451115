import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ListPage = Loadable(lazy(() => import('./ListPage')));
const Add = Loadable(lazy(() => import('./Add')));

const userRoutes = [
    { path: '/auth/users/list', element: <ListPage />, auth: [authRoles.admin] },
    { path: '/auth/users/add', element: <Add />, auth: [authRoles.admin] }
    
];

export default userRoutes;
