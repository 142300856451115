//import { useEffect } from 'react';
import { themes } from '../MatxTheme/initThemes';
//import layout1Settings from './Layout1/Layout1Settings';

// UPDATE BELOW CODE
// DOC http://demos.ui-lib.com/matx-react-doc/layout.html



export const MatxLayoutSettings = {
    activeLayout: 'layout1', // layout1, layout2
    activeTheme: 'BaseAdmin_theme', // View all valid theme colors inside MatxTheme/themeColors.js
    perfectScrollbar: false,

    themes: themes,
    layout1Settings: {
        leftSidebar: {
            show: true,
            mode: 'full', // full, close, compact, mobile,
            theme: 'BaseAdmin_leftSidebar', // View all valid theme colors inside MatxTheme/themeColors.js
            bgimgurl: '/assets/images/sidebar/sidebar-bg-dark.jpg',
        },
        topbar: {
            show: true,
            fixed: true,
            theme: 'BaseAdmin_topbar', // View all valid theme colors inside MatxTheme/themeColors.js
        },
    },

    secondarysidebar: {
        show: true,
        open: false,
        theme: 'BaseAdmin_footer', // View all valid theme colors inside MatxTheme/themeColors.js
    },
    // Footer options
    footer: {
        show: true,
        fixed: false,
        theme: 'BaseAdmin_footer', // View all valid theme colors inside MatxTheme/themeColors.js
    },
};


//OLD CODE
//export const MatxLayoutSettings = {
//    activeLayout: 'layout1', // layout1, layout2
//    activeTheme: 'blue', // View all valid theme colors inside MatxTheme/themeColors.js
//    perfectScrollbar: false,

//    themes: themes,
//    layout1Settings, // open Layout1/Layout1Settings.js

//    secondarySidebar: {
//        show: true,
//        open: false,
//        theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
//    },
//    // Footer options
//    footer: {
//        show: true,
//        fixed: false,
//        theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
//    },
//};
