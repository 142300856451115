import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';

const ExpensesPage = Loadable(lazy(() => import('./ExpensesPage')));
const FlowPage = Loadable(lazy(() => import('./FlowPage')));
const ManagementPage = Loadable(lazy(() => import('./ManagementPage')));
const FinancialPage = Loadable(lazy(() => import('./FinancialPage')));
const CoastCenterPage = Loadable(lazy(() => import('./CoastCenterPage')));

const expensesRoutes = [
    { path: '/auth/expense/main', element: <ExpensesPage />, auth: [authRoles.sa] },
    { path: '/auth/expense/management', element: <ManagementPage />, auth: [authRoles.sa] },
    { path: '/auth/expense/financial', element: <FinancialPage />, auth: [authRoles.sa] },
    { path: '/auth/expense/flow', element: <FlowPage />, auth: [authRoles.sa] },
    { path: '/auth/expense/coastCenter', element: <CoastCenterPage />, auth: [authRoles.sa] },

    
];

export default expensesRoutes;
