import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';


const PickTimePage = Loadable(lazy(() => import('./PickTimePage')));
const BookingPage = Loadable(lazy(() => import('./BookingPage')));
const DocumentsPage = Loadable(lazy(() => import('./DocumentsPage')));
const AdminRHPage = Loadable(lazy(() => import('./AdminRHPage')));


const pimRoutes = [
    { path: '/auth/rh/pick', element: <PickTimePage />, auth: [authRoles.sa] },
    { path: '/auth/rh/booking', element: <BookingPage />, auth: [authRoles.sa] },
    { path: '/auth/rh/documents', element: <DocumentsPage />, auth: [authRoles.sa] },
    { path: '/auth/rh/administration', element: <AdminRHPage />, auth: [authRoles.sa] },
   
    
];

export default pimRoutes;
