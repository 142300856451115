import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';


const ShiftPage = Loadable(lazy(() => import('./ShiftPage')));


const shiftRoutes = [
    { path: '/auth/shift', element: <ShiftPage />, auth: [authRoles.sa] },
    
];

export default shiftRoutes;
