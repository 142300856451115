import AuthGuard from 'app/auth/AuthGuard';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import profileRoutes from 'app/views/profile/ProfileRoutes';
import userRoutes from 'app/views/users/UsersRoutes';
import settingsRoutes from 'app/views/settings/SettingsRoutes';
import configurationsRoutes from 'app/views/configurations/ConfigurationsRouter';
import pimRoutes from 'app/views/pim/PimRoutes';
import docRoutes from 'app/views/doc/DocRoutes';
import entityRoutes from 'app/views/entity/EntityRoutes';
import locationRoutes from 'app/views/inventory/location/LocationRoutes'
import stockRoutes from 'app/views/inventory/stock/StockRoutes'
import budgetRoutes from 'app/views/IG/management/budget/BudgetRoutes'
import productionRoutes from 'app/views/IG/management/production/ProductionRoutes'
import requestRoutes from 'app/views/IG/management/request/RequestRoutes'
import schedularRoutes from 'app/views/IG/management/schedular/SchedularRoutes'
import controlRoutes from 'app/views/IG/management/control/ControlRoutes'
import rhRoutes from 'app/views/RH/RHRoutes';
import taskListRoutes from "./views/tickets/TaskListRoutes";
import expensesRoutes from "./views/expenses/ExpensesRoutes";
import shiftRoutes from "./views/shift/ShiftRoutes";
import maintenanceRoutes from "./views/maintenance/MaintenanceRoutes";
import eventsRoutes from 'app/views/events/eventsRoutes';
import budgetRoutesTft from 'app/views/TFP/management/budget/BudgetRoutes';


const routes = [
    {
        element: (
            <AuthGuard>
                <MatxLayout />
            </AuthGuard>
        ),
        children: [
            ...dashboardRoutes,
            ...profileRoutes,
            ...userRoutes,
            ...settingsRoutes,
            ...configurationsRoutes,
            ...pimRoutes,
            ...docRoutes,
            ...entityRoutes,
            ...locationRoutes,
            ...stockRoutes,
            ...budgetRoutes,
            ...productionRoutes,
            ...requestRoutes,
            ...schedularRoutes,
            ...rhRoutes,
            ...controlRoutes,
            ...taskListRoutes,
            ...expensesRoutes,
            ...shiftRoutes,
            ...maintenanceRoutes,
            ...eventsRoutes,
            ...budgetRoutesTft
        ],
    },
    ...sessionRoutes,
    { path: '/', element: <Navigate to="auth/dashboard" /> },
    { path: '*', element: <NotFound /> },
];

export default routes;
