import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const EventsPage = Loadable(lazy(() => import('./EventsPage')));
//const NewDoc = Loadable(lazy(() => import('./NewDoc')));


const eventsRoutes = [
    { path: '/auth/events/list', element: <EventsPage />, auth: [authRoles.sa] }
    
    
];

export default eventsRoutes;
