import React, { createContext, useEffect, useReducer, useState } from 'react'
import { HubConnectionBuilder } from "@microsoft/signalr";
import useAuth from '../hooks/useAuth';
import useCompany from '../hooks/useCompany';

const reducer = (state, action) => {
    switch (action.type) {
        case 'ADD_CONNECTION': {
            return {
                ...state,
                connectionHub: action.payload,
            }
        }
        case 'LOAD_CONNECTION': {
            return {
                ...state,
                connectionHub: action.payload,
            }
        }
        case 'CLEAR_CONNECTION': {
            return {
                ...state,
                connectionHub: null,
            }
        }

        default: {
            return { ...state }
        }
    }
}

const SignalRContext = createContext({
    connectionHub: null,
    connect: () => Promise.resolve(),
    disconnect: () => Promise.resolve()
})

export const SignalRProvider = ({ settings, children }) => {
    const [, dispatch] = useReducer(reducer, []);
    const { user } = useAuth();
    const { getSelectedCompany } = useCompany();
    const [connection, setConnection] = useState(null);
    
    const connect = async () => {
        try {
            if (user) {

                var url = process.env.REACT_APP_API_URL;
                const connect = new HubConnectionBuilder()
                    .withUrl(url + "/hubNotification")
                    .withAutomaticReconnect()
                    .build();
                dispatch({ type: 'ADD_CONNECTION', payload: connect });
                setConnection(connect);

            }
        } catch (e) {
            console.error(e)
        }
    }

    const disconnect = async () => {
        if (connection) {
            connection.stop().then(() => {
                dispatch({ type: 'CLEAR_CONNECTION' });
                setConnection(null);
                console.log("Connection closed");
            }).catch(err => console.error(err));
        }
    }



    useEffect(() => {
        (async () => {
            await connect();
        })();
    }, [])


    useEffect(() => {
        (async () => {
            try {
                var cacheCompany = await getSelectedCompany();
                if (connection) {
                    if (user) {
                        //console.info("State:", connection?.state)
                        if (connection?.state === 'Disconnected') {
                            connection.start().then(() => {
                                connection?.invoke("JoinUser", user?.id?.toString());
                                if (cacheCompany !== null) {
                                    connection?.invoke("JoinGroup", cacheCompany?.id?.toString());
                                    connection?.send("SendMessageToGroup", cacheCompany?.id?.toString(), user?.firstName + " iniciou sesão.");
                                } else {
                                    if (user && user?.accountRoles.some(item => item.role === "SA")) {
                                        connection?.invoke("JoinGroup", "SA");

                                    } else {
                                        user?.accountCompanies.forEach(company => {
                                            connection?.invoke("JoinGroup", company?.id?.toString());
                                        });
                                    }
                                }
                            })
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            }

        })();



    }, [connection, user])

    return (
        <SignalRContext.Provider
            value={{
                connectionHub: connection,
                connect,
                disconnect
            }}
        >
            {children}
        </SignalRContext.Provider>
    )
}

export default SignalRContext;
