import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';


const LocationPage = Loadable(lazy(() => import('./LocationPage')));
//const NewEquipment = Loadable(lazy(() => import('./NewEquipment')));
const ChargeLocationPage = Loadable(lazy(() => import('./ChargeLocationPage')));

const locationRoutes = [
    { path: '/auth/inventory/location/list', element: <LocationPage />, auth: [authRoles.sa] },
    //{ path: '/auth/inventory/equipment/add', element: <NewEquipment />, auth: [authRoles.sa] },
    { path: '/auth/inventory/location/charge/file', element: <ChargeLocationPage />, auth: [authRoles.sa] },
    
];

export default locationRoutes;
