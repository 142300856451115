import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';


const BudgetPage = Loadable(lazy(() => import('./BudgetPage')));


const budgetRoutes = [
    { path: '/auth/tfp/management/budget', element: <BudgetPage />, auth: [authRoles.sa] },
    
    
];

export default budgetRoutes;
