import { MatxSuspense } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { MatxLayouts } from './index';
import SignalR from '../SignalR/SignalR';



const MatxLayout = (props) => {
    const { settings } = useSettings();
    const Layout = MatxLayouts[settings.activeLayout];
    


    return (
        <MatxSuspense>
            <SignalR></SignalR>
            <Layout {...props} />
        </MatxSuspense>
    );
};

export default MatxLayout;
