import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';


const StockPage = Loadable(lazy(() => import('./StockPage')));

const stockRoutes = [
    { path: '/auth/inventory/stock', element: <StockPage />, auth: [authRoles.sa] },
    
];

export default stockRoutes;
